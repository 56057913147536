import axios from "@/libs/axios";

let base = '';

// 用户
export const login = (param) => {
    return axios.request({
        url: `${base}/api/user/login`,
        params: param,
        method: 'get'
    });
}
export const logout = () => {
    return axios.request({
        url: `${base}/api/user/logout`,
        method: 'get'
    });
}
export const getCurrentUser = () => {
    return axios.request({
        url: `${base}/api/user/current`,
        method: 'get'
    });
}
export const modifyPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/modifyPwd`,
        method: 'post',
        data: param
    });
}
export const getUserList = (param) => {
    return axios.request({
        url: `${base}/api/user/list`,
        method: 'post',
        data: param
    });
}
export const getUserDetail = (id) => {
    return axios.request({
        url: `${base}/api/user/` + id,
        method: 'get'
    });
}
export const saveUser = (param) => {
    return axios.request({
        url: `${base}/api/user/save`,
        method: 'post',
        data: param
    });
}
export const deleteUser = (id) => {
    return axios.request({
        url: `${base}/api/user/del/` + id,
        method: 'get'
    });
}
export const resetPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/resetPwd`,
        method: 'get',
        params: param
    });
}

// 代理商
export const getAgentList = (param) => {
    return axios.request({
        url: `${base}/api/agent/list`,
        method: 'post',
        params: param
    });
}
export const getAgentDetail = (id) => {
    return axios.request({
        url: `${base}/api/agent/` + id,
        method: 'get'
    });
}
export const saveAgent = (param) => {
    return axios.request({
        url: `${base}/api/agent/save`,
        method: 'post',
        data: param
    });
}
export const deleteAgent = (id) => {
    return axios.request({
        url: `${base}/api/agent/del/` + id,
        method: 'get'
    });
}

// 设备
export const getDeviceList = (param) => {
    return axios.request({
        url: `${base}/api/device/list`,
        method: 'post',
        params: param
    });
}
export const getDeviceDetail = (id) => {
    return axios.request({
        url: `${base}/api/device/` + id,
        method: 'get'
    });
}
export const saveDevice = (param) => {
    return axios.request({
        url: `${base}/api/device/save`,
        method: 'post',
        data: param
    });
}
export const deleteDevice = (id) => {
    return axios.request({
        url: `${base}/api/device/del/` + id,
        method: 'get'
    });
}
export const sendChemicalsCmd = () => {
    return axios.request({
        url: `${base}/api/chemicals/sendCmd`,
        method: 'get'
    });
}


// 上报数据
export const getReportDataList = (param) => {
    return axios.request({
        url: `${base}/api/report/list`,
        method: 'post',
        data: param
    });
}
export const getChemicalsList = (param) => {
    
}


// 分析
export const countHome = () => {
    return axios.request({
        url: `${base}/api/analy/count`,
        method: 'get'
    });
}
export const countReport = (param) => {
    return axios.request({
        url: `${base}/api/analy/report`,
        method: 'get',
        params: param
    });
}